@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white;
}

.profile-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}